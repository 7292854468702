
import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';
import MicroService from "@/models/MicroService";
import {SubscriptionStatus} from "@/enum/SubscriptionStatus.enum";
import {PriceType} from "@/enum/PriceType.enum";

@Component
export default class MicroServiceCard extends Vue {
  @Prop({required: true})
  private service!: MicroService;

  @Prop({required: true})
  public onServiceClicked!: (service: MicroService) => void;

  private get isBooked() : boolean {
    return this.service.subscriptionStatus === SubscriptionStatus.SUBSCRIBED;
  }

  private get isCancelled(): boolean {
    return this.service.subscriptionStatus === SubscriptionStatus.CANCELLED;
  }

  private get isUnSubscribed(): boolean {
    return this.service.subscriptionStatus === SubscriptionStatus.NOT_SUBSCRIBED;
  }

  /**
   * is called when the user clicks on the card, calls passed callback function with the clicked microService
   * @private
   */
  private onMicroServiceClicked() {
    this.onServiceClicked(this.service);
  }

  /**
   * returns the price with the price type in format
   * @private
   */
  private get priceInfo() {
    return this.$t('MICRO_SERVICES.PRICE_TYPE.'+ this.service.priceType,
        {'amount': this.$formatCurrency(this.service.servicePrice)});
  }

  /**
   * gets the button texts, checks if the microservice is free and shows different buy button text
   * for the type
   * @private
   */
  private get buyBtnText() {
    if(this.service.priceType=== PriceType.FREE) {
      return this.$t('GENERAL.LEARN_MORE');
    }

    return this.$t('GENERAL.BUY');
  }
}
